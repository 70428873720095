/* MAIN JS FILE */

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


$(document).ready(function(){

	// TOUCH EVENTS
	$(function () { 
	    addTouchEvents();   
	});

	addTouchEvents = function() {
	    var isTouchDevice = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);

	    if (isTouchDevice) {
	        //replace link clicks with ontouchend events for more responsive UI
	        $("a", "[onclick]").on("touchstart",function(e) {
	            $(this).trigger("click");
	            e.preventDefault();
	            return false;
	        });
	    }
	}

	// SCROLL TO
	$('.scrollto').each(function(){
		$(this).click(function(e){
			e.preventDefault();
			var anchor = $(this).attr('href');
			$([document.documentElement, document.body]).animate({
		        scrollTop: $(anchor).offset().top
		    },600);
		});  
	});

	// SET SCOLL ANCHOR
	function isScrolledIntoView(elem) {
	  var docViewTop = $(window).scrollTop();
	  var docViewBottom = docViewTop + $(window).height();

	  var elemTop = $(elem).offset().top;
	  var elemBottom = elemTop + $(elem).height();

	  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	}

	$(document).scroll(function(e) {
		var anchors = $('.scrollanchor');
		for (var i = 0; i < anchors.length; ++i) {
			if (isScrolledIntoView(anchors[i])){
				var href = $(anchors[i]).attr('id');
				if(history.pushState) {
					history.pushState(null, null, '#'+href);
				}
				else {
					location.hash = '#'+href;
				}
				location.hash = href.slice(href.indexOf('#') + 1);
				break;
			}
		}
	});

	// var currentHash = "#initial_hash"
 //    $(document).scroll(function () {
	// 	$('.anchor_tags').each(function () {
	// 		var top = window.pageYOffset;
	// 		var distance = top - $(this).offset().top;
	// 		var hash = $(this).attr('id');
	// 		// 30 is an arbitrary padding choice, 
	// 		// if you want a precise check then use distance===0
	// 		if (distance < 30 && distance > -30 && currentHash != hash) {
	// 		    window.location.hash = (hash);
	// 		    currentHash = hash;
	// 		}
	// 	});
 //    });


	// var myElements = $('.scrollanchor');
	// $(window).scroll(function(e) {
	// 	var scrollTop = $(window).scrollTop();
	// 	myElements.each(function(i, el) {
	// 		console.log($(myElements[i+1]))
	// 		if ($(this).offset().top > scrollTop && $(myElements[i+1]).offset().top < scrollTop) {
	// 			location.hash = this.id;
	// 		}
	// 	});
	// });

	//SLIDER 
	var workslider = $('#workslider').owlCarousel({
		dots: true,
	    loop:true,
	    responsiveClass:true,
	    responsive:{
	        0:{
	            items:1
	        },
	        768:{
	            items:2
	        },
	        1024:{
	            items:3
	        },
	        1280:{
	            items:4
	        },
	        1920:{
	            items:5
	        }
	    }
	})

	// CONTACT LINK 
	$('.slider-to-contact').click(function(){
		workslider.trigger('to.owl.carousel', [0, 300]);
	});

	// HEART BUMP
	$('.icn-heart').click(function(e){
		var $this = $(this);
		$this.addClass('animate');
		setTimeout(function(){
		  $this.removeClass('animate');
		}, 3500);
	})


});

